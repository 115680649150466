export const optimyRoutes = {
  leads: 'leads',
  bookingFormConfig: 'booking-form-config',
  cancelSession: 'cancel-session',
  cobrowse: 'cobrowse',
  dashboard: 'dashboard',
  editAppointment: 'edit-appointment',
  empty: '',
  enroll: 'enroll',
  existingSession: 'existing-session',
  liveChatSession: 'live-chat',
  login: 'login',
  requestAppointment: 'request-appointment',
  save: 'save',
  saveOutlet: {
    queue: 'queue',
    agentList: 'agent-list',
    history: 'history',
    reports: 'reports',
    activeOutreach: 'active-outreach',
    inbox: 'inbox'
  },
  saveRedirect: 'invite/agent',
  endCall: 'end-call',
  schedule: 'schedule',
  scheduledSession: 'scheduled-session',
  scheduleEdit: 'schedule/edit',
  settings: 'settings',
  settingsOutlet: {
    bookingConfig: 'booking-config',
    bookingConfigOutlet: {
      generalSetting: 'general-setting',
      regularHours: 'regular-hours',
      exceptions: 'exceptions'
    },
    notificationConfig: 'notification-config',
    notificationOutlet: {
      inbound: 'inbound',
      activeOutreach: 'active-outreach',
      invites: 'invites'
    }
  },
  taskList: 'taskList',
  tempOutlets: {
    root: 'root',
    dashboard: 'dashboard',
    queue: 'queue',
    outreach: 'outreach',
    teams: 'teams',
    history: 'history',
    reports: 'reports',
    settings: 'settings',
    alerts: 'alerts',
    board: 'board',
    scheduled: 'scheduled',
    call: 'call',
    leads: 'leads',
    autoDashboard: 'auto-dashboard',
    executive: 'executive',
    operational: 'operational',
    waiting: 'waiting',
    inProgress: 'in-progress',
    ended: 'ended',
    details: 'details',
    browsingHistory: 'browsing-history',
    sales: 'sales'
  },
  video: 'video',
  videoCall: 'video-call',
  wildCard: '**'
};
