import { Injectable } from '@angular/core';
import { RoomData, RoomKeys } from '../../../../core/constants/video-config';
import {
  InviteReadStatus,
  MemberUpdateRes,
  MessageType,
  MsgUserType,
  SendReq
} from '../../../../core/constants/chat-config';
import { Constants } from '../../../../core/constants/constants';
import {
  CallKeys,
  GeneralQueueData,
  QueueCall,
  QueueStatus,
  QueueUpdateReq
} from '../../../root/new-queue/modals/queue.config';
import { firstValueFrom, forkJoin } from 'rxjs';
import {
  Participant,
  RoomInfo
} from '../../../root/new-queue/modules/call/modals/call-config';
import * as appActions from '../../../../state/app.actions';
import { optimyRoutes } from '../../../../core/constants/optimyRoutes';
import { UserDetails } from '../../../../core/constants/dashboard-modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CallService } from '../../../root/new-queue/modules/call/services/call-service/call.service';
import { ChatService } from '../../../root/new-queue/modules/call/services/chat/chat.service';
import { Store } from '@ngrx/store';
import * as AppState from '../../../../state/app.state';
import { DeviceTypeCheckService } from '../device-type-check/device-type-check.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { QueueService } from '../../../root/new-queue/services/queue-service/queue.service';
import { OutBoundInfo } from '../../../../core/constants/outbound-config';
import { RootUtilsService } from '../root-utils/root-utils.service';
import { SharedService } from '../../../../core/services/shared-service/shared.service';
import { SocketService } from '../../../../core/services/socket/socket.service';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class InviteLinkCheckService {
  token: string;
  userDetails: UserDetails;
  isMobile = false;

  outBoundInfo: OutBoundInfo;
  roomName: string;
  chatMembers: Participant[];
  inProgressCall: QueueCall;

  isOutBound = false;
  isWebNotification = false;
  isFromInviteLink: boolean;

  constructor(
    private appStore: Store<AppState.State>,
    private callService: CallService,
    private chatService: ChatService,
    private deviceTypeCheckService: DeviceTypeCheckService,
    private queueService: QueueService,
    private router: Router,
    private route: ActivatedRoute,
    private rootUtilsService: RootUtilsService,
    private sharedService: SharedService,
    private socketService: SocketService,
    private toaster: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {}

  checkCallStatus(
    roomInfo: RoomData | any,
    userDetails: UserDetails,
    token: string,
    outboundInfo: OutBoundInfo,
    isNotification?: boolean,
    isFromInviteLink?: boolean
  ) {
    this.userDetails = userDetails;
    this.token = token;
    this.outBoundInfo = outboundInfo;
    this.isWebNotification = isNotification;
    this.isFromInviteLink = isFromInviteLink;
    if (
      roomInfo.status === InviteReadStatus.missed &&
      roomInfo.status === InviteReadStatus.closed &&
      roomInfo?.status === InviteReadStatus.cancelled
    ) {
      this.toaster.error(this.translate.instant('save.callNotInProgress'));
      this.redirectToSubDomain(Constants.routes.dashboard, roomInfo);
    } else if (roomInfo?.status === InviteReadStatus.scheduled) {
      const userTimezone = moment.tz.guess();
      const defaultTimezone = moment.tz(
        roomInfo?.start_dt,
        Constants.estTimezone
      );
      const time = `${moment().format('ddd, MMM DD YYYY')} at ${defaultTimezone
        .tz(userTimezone)
        .format('LT')}`;
      this.toaster.error(
        this.translate.instant('save.callInScheduled', { time: time })
      );
      this.redirectToSubDomain(Constants.routes.dashboard, roomInfo);
    } else if (
      roomInfo.status !== InviteReadStatus.missed &&
      roomInfo.status !== InviteReadStatus.closed &&
      roomInfo?.status !== InviteReadStatus.cancelled &&
      roomInfo?.status !== InviteReadStatus.scheduled
    ) {
      if (
        roomInfo.tenantid === this.userDetails.tenant_id ||
        this.userDetails.queue_list.findIndex(
          (que) => que.queue_id === roomInfo.queue_id
        ) !== -1
      ) {
        this.redirectCall(roomInfo);
      } else {
        this.callService.sendJoinCallSystemMessageUpdate$.next(true);
        this.toaster.error(
          this.translate.instant('save.notAuthorizedJoinCall')
        );
        this.redirectToSubDomain(Constants.routes.dashboard, roomInfo);
      }
    }
  }

  redirectCall(roomInfo: RoomData | any) {
    this.utils.setVideoRoomStatus(
      [RoomKeys.virtualBackgroundImg],
      [roomInfo?.call_config?.virtual_bg]
    );
    this.roomName = roomInfo?.room_name;
    this.isOutBound =
      roomInfo.outbound && roomInfo.outbound_accepted_timestamp === null;
    this.utils.setGuestInfo(roomInfo);
    if (
      roomInfo?.status === InviteReadStatus.adHoc ||
      roomInfo?.status === InviteReadStatus.outBound ||
      roomInfo?.status === InviteReadStatus.pending
    ) {
      const req: QueueUpdateReq = {
        status: QueueStatus.inProgress,
        attr_update: JSON.stringify({
          room_name: this.roomName
        })
      };
      this.queueService
        .updateQueuePosition(req, roomInfo?.queue_position_id)
        .subscribe(() => {
          this.checkIfSecondAgentExist(roomInfo);
        });
    } else {
      this.checkIfSecondAgentExist(roomInfo);
    }
  }

  async checkIfSecondAgentExist(roomInfo: RoomData | any) {
    this.chatMembers = await firstValueFrom(
      this.chatService.getMemberList(roomInfo?.room_name)
    );
    const agentCount =
      this.chatMembers?.filter((participant) => {
        return (
          this.rootUtilsService.getAgentInfo(participant) &&
          !participant?.attributes.disconnected_timestamp
        );
      })?.length || 0;
    if (agentCount > 0) {
      this.rootUtilsService.setCallSpecificInfoInLocal(
        roomInfo?.queue_position_id,
        [CallKeys.inSmallRoom],
        [false]
      );
      this.checkSmallRoomExistAndJoin(roomInfo);
    } else {
      this.checkAlreadyMember(roomInfo, false);
    }
  }

  checkSmallRoomExistAndJoin(roomInfo: RoomData | any) {
    if (roomInfo?.small_room_chat) {
      const smallRoomName = roomInfo?.small_room_chat;
      this.rootUtilsService.setCallSpecificInfoInLocal(
        roomInfo?.queue_position_id,
        [CallKeys.smallRoomName],
        [smallRoomName]
      );
      this.socketService.joinRoom([smallRoomName]);
      this.checkAlreadyMember(roomInfo, true);
    } else {
      this.createSmallChatRoom(roomInfo);
    }
  }

  createSmallChatRoom(roomInfo: RoomData | any) {
    this.callService.createChannel().subscribe((response) => {
      const smallRoomName = response?.sid;
      this.socketService.joinRoom([smallRoomName]);
      let req: QueueUpdateReq = {};
      if (smallRoomName) {
        req['small_room_chat'] = smallRoomName;
      }
      this.queueService
        .updateQueuePosition(
          { attr_update: JSON.stringify(req) },
          roomInfo?.queue_position_id
        )
        .subscribe(() => {
          this.rootUtilsService.setCallSpecificInfoInLocal(
            roomInfo?.queue_position_id,
            [CallKeys.smallRoomName],
            [smallRoomName]
          );
          this.checkAlreadyMember(roomInfo, true);
        });
    });
  }

  checkAlreadyMember(roomInfo: RoomData | any, toSmall: boolean) {
    const alreadyMember = this.chatMembers.find(
      (res) => +res?.identity === this.userDetails.user_id
    );
    if (alreadyMember) {
      if (alreadyMember?.attributes?.disconnected_timestamp) {
        this.updateMemberAndJoinAgain(alreadyMember, roomInfo);
      } else {
        this.memberUpdatedAndSendSystemMessage(roomInfo, alreadyMember?.sid);
      }
    } else {
      this.addMembersToCall(roomInfo, toSmall);
    }
  }

  updateMemberAndJoinAgain(alreadyMember, roomInfo: RoomData) {
    let req = {
      ...alreadyMember?.attributes,
      inSmallRoom: false,
      roomName: roomInfo?.room_name,
      disconnected_timestamp: null
    };
    this.chatService
      .updateMember(req, alreadyMember?.sid)
      .subscribe((res: MemberUpdateRes) => {
        this.memberUpdatedAndSendSystemMessage(
          roomInfo,
          alreadyMember?.sid,
          res?.getMemberRes
        );
      });
  }

  addMembersToCall(roomInfo: RoomData | any, toSmall: boolean) {
    const attributes = {
      first_name: this.userDetails?.first_name,
      last_name: this.userDetails?.last_name,
      avatar: this.userDetails?.avatar,
      roomName: roomInfo?.room_name,
      inSmallRoom: false
    };
    this.chatService
      .addMember({
        channelSid: roomInfo?.room_name,
        identity: this.userDetails?.user_id,
        attributes
      })
      .subscribe((res) => {
        this.memberUpdatedAndSendSystemMessage(roomInfo, res.sid);
      });
  }

  memberUpdatedAndSendSystemMessage(roomInfo, sid, members?) {
    this.chatService
      .memberUpdated(roomInfo?.room_name, members)
      .subscribe(() => {
        this.utils.setVideoRoomStatus([RoomKeys.memberSid], [sid]);
        const agentName = `${
          this.userDetails?.first_name
        } ${this.userDetails?.last_name.charAt(0)}`;
        const req: SendReq = {
          channelSid: roomInfo?.room_name,
          message: '',
          identity: 'system',
          attributes: {
            message_type: 'joined_left',
            user_type: MsgUserType.agent,
            user_id: this.userDetails?.user_id,
            action: MessageType.joined,
            user_name: agentName
          }
        };
        this.chatService.createMessage(req).subscribe(
          () => {
            this.navigateToCall(roomInfo);
            console.log('agent joined message added');
            this.toaster.success(
              this.translate.instant('chat.agentCallJoinMessage')
            );
            this.callService.sendJoinCallSystemMessageUpdate$.next(true);
          },
          () => {
            console.log('agent joined message failed to add');
            this.navigateToCall(roomInfo);
          }
        );
      });
  }

  navigateToCall(roomInfo: RoomData | any) {
    if (this.isOutBound) {
      this.handleOutboundCall(roomInfo);
    } else if (roomInfo?.status === InviteReadStatus.adHoc) {
      this.handleAdhocCall(roomInfo);
    } else {
      this.checkIsMobileAndNavigate(roomInfo);
    }
  }

  handleAdhocCall(roomInfo: RoomData) {
    const roomData: RoomInfo = {
      room_name: this.roomName,
      room_status: QueueStatus.inProgress,
      queue_position_id: roomInfo?.queue_position_id
    };
    this.callService.passRoomInfo(roomData).subscribe(() => {
      this.checkIsMobileAndNavigate(roomInfo);
    });
  }

  handleOutboundCall(roomInfo: RoomData) {
    if (this.outBoundInfo?.agentMessage) {
      this.sendMessage();
    }
    this.appStore.dispatch(
      new appActions.UpdatePendingCallsAction(
        [CallKeys.outboundCall],
        [true],
        roomInfo?.room_name
      )
    );
    this.rootUtilsService.setCallSpecificInfoInLocal(
      roomInfo?.queue_position_id,
      [CallKeys.outboundCall],
      [true]
    );
    const roomData: RoomInfo = {
      room_name: this.roomName,
      room_status: QueueStatus.inProgress,
      queue_position_id: roomInfo?.queue_position_id
    };
    const passRoomData = this.callService.passRoomInfo(roomData);
    const initiateOutbound = this.callService.initiateOutbound(
      this.token,
      this.outBoundInfo.domainId,
      this.roomName
    );
    const forkCalls = [initiateOutbound, passRoomData];
    forkJoin(forkCalls).subscribe(() => {
      this.checkIsMobileAndNavigate(roomInfo);
    });
  }

  sendMessage() {
    const req: SendReq = {
      channelSid: this.roomName,
      message: this.outBoundInfo?.agentMessage,
      identity: `${this.userDetails.user_id}`
    };
    this.chatService.createMessage(req).subscribe((res) => {
      console.log('message send successfully', res);
    });
  }

  checkIsMobileAndNavigate(roomInfo: RoomData | any) {
    if (this.isWebNotification) {
      this.appStore.dispatch(new appActions.LoadQueueCallsAndListsAction());
    }
    this.rootUtilsService.setLocalStorageInfos(
      true,
      [GeneralQueueData.callQueueId],
      [roomInfo?.queue_position_id]
    );
    if (this.isFromInviteLink) {
      this.rootUtilsService.setCallSpecificInfoInLocal(
        roomInfo?.queue_position_id,
        [CallKeys.isFromInviteLink],
        [true]
      );
    }
    if (!location.href.includes('dashboard/queue/call')) {
      this.rootUtilsService.getAndSetAgentFilterData(this.userDetails);
      this.redirectToSubDomain(
        `${optimyRoutes.tempOutlets.dashboard}/${optimyRoutes.tempOutlets.queue}/${optimyRoutes.tempOutlets.call}`,
        roomInfo
      );
    }
  }

  redirectToSubDomain(url, roomInfo) {
    const regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
    if (
      location.href.includes('localhost') ||
      !!location.host.match(regex) ||
      !roomInfo?.tenantcode
    ) {
      this.router.navigateByUrl(url);
    } else {
      const newUrl = `${location.origin}/${url}`;
      setTimeout(() => {
        window.location.href = newUrl.replace(
          /^https:\/\//,
          `https://${roomInfo?.tenantcode}.`
        );
      }, 1000);
    }
  }
}
